import { format } from 'date-fns';

export const parseTime = (value: string) => {
  if (value?.includes?.(':')) {
    const [hours, minutes] = value.split(':');
    return { hours, minutes };
  }

  const currentDate = new Date();
  const formattedDate = format(currentDate, 'HH:mm');
  const [hours, minutes] = formattedDate.split(':');

  return { hours, minutes };
};
