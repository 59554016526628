import { createEffect, createEvent, createStore, sample } from 'effector';
import { TMerchantInfo, TGetMerchantInfoFx } from '../types';
import { LOCAL_STORAGE_MERCHANT_INFO } from 'shared/config/constants';
import { authApi } from '../api';
import { servicesApi } from 'entities/Services/api';
import { spread } from 'patronum';
import { servicesModel } from 'entities/Services/model';

const resetAll = createEvent();

const devInitMerchantInfo =
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_MERCHANT_INFO)) ?? null;
const initMerchantInfo =
  process.env.NODE_ENV === 'development' ? devInitMerchantInfo : null;

const setMerchantInfo = createEvent<TMerchantInfo>();
const $merchantInfo = createStore<TMerchantInfo>(null);

sample({
  clock: setMerchantInfo,
  target: $merchantInfo,
});

const getMerchantInfo = createEvent<string>();
const getMerchantInfoFx = createEffect<TGetMerchantInfoFx>(async (params) => {
  const merchantInfo = (await authApi.fetchJwtToken(params)).data;

  localStorage.setItem(
    LOCAL_STORAGE_MERCHANT_INFO,
    JSON.stringify(merchantInfo),
  );

  const services = (await servicesApi.getServices()).data;

  return {
    merchantInfo,
    services,
  };
});

sample({
  clock: getMerchantInfo,
  source: $merchantInfo,
  filter: (merchantInfo) => Boolean(!merchantInfo),
  fn: (_, params) => params,
  target: getMerchantInfoFx,
});

sample({
  clock: getMerchantInfoFx.doneData,
  fn: (res) => ({
    setMerchantInfo: res.merchantInfo,
    setServices: res.services,
  }),
  target: spread({
    targets: {
      setMerchantInfo: $merchantInfo,
      setServices: servicesModel.services.stores.$list,
    },
  }),
});

sample({
  clock: resetAll,
  fn: () => null,
  target: $merchantInfo,
});

export const creds = {
  stores: {
    $merchantInfo,
  },
  events: {
    setMerchantInfo,
    getMerchantInfo,
    resetAll,
  },
};
