export enum endPoints {
  DEFAULT_PAGE = '/',
  LOGIN = '/login/:merchant_id/:web_session',
  ONBOARD = '/onboard',
  MY_SHOPS = '/my-shops',
  CREATE = '/create',
  HOME = '/home',
  SHOP = '/shop',
  ORDERS = '/orders',
  ORDER = '/orders/:id',
  MENU = '/menu',
}
