import React, { FC } from 'react';
import { regions } from './config';
import { IOption } from 'shared/ui/molecules/Options/types';
import { Options } from 'shared/ui/molecules/Options';

interface IRegionSelectorProps {
  value: number;
  onChange: (value: IOption) => void;
  onClose?: () => void;
}

export const RegionSelector: FC<IRegionSelectorProps> = ({
  value,
  onChange,
  onClose,
}) => {
  const optionValue = regions.find((region) => region.id === value);
  return (
    <Options
      options={regions}
      value={optionValue}
      onChange={onChange}
      onClose={onClose}
    />
  );
};
