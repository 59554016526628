import React, { FC, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IFileUpload } from './types';
import { Text } from 'shared/ui/atoms/Text';
import { fileUploadCss } from './style';
import { SpinnerBlock } from 'shared/ui/atoms/SpinnerBlock';
import { Button } from 'shared/ui/atoms/Button';

export const FileUpload: FC<IFileUpload> = ({
  hasError = false,
  className = '',
  isPending = false,
  src = null,
  buttonLabel = 'fileUpload.buttonLabel',
  withButton = false,
  buttonClassName = '',
  onChange,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const source = src instanceof File ? URL.createObjectURL(src) : src;

  const onClick = () => {
    if (!isPending) {
      inputRef.current?.click();
      inputRef.current.onchange = (e) => {
        onChange(inputRef.current.files);
      };
    }
  };

  return (
    <div className={fileUploadCss.container}>
      <div
        className={`${fileUploadCss.fileContainer} ${className}`}
        data-has-error={hasError}
        onClick={onClick}>
        {src && !isPending ? (
          <img src={source} />
        ) : (
          <div className={fileUploadCss.wrapper}>
            {isPending ? (
              <SpinnerBlock size={40} />
            ) : (
              <Text size="h3" weight="medium" className={fileUploadCss.label}>
                {t('fileUpload.title')}
              </Text>
            )}
          </div>
        )}
        <input ref={inputRef} hidden type="file" accept="image/*" />
      </div>
      {withButton && (
        <Button
          styleType="tertiary"
          onClick={onClick}
          fontSize="14"
          className={`${fileUploadCss.uploadFileBtn} ${buttonClassName}`}>
          {t(buttonLabel)}
        </Button>
      )}
      <Text size="subtext" color="secondary" className={fileUploadCss.subtitle}>
        <Trans i18nKey="fileUpload.requirements" components={{ br: <br /> }} />
      </Text>
    </div>
  );
};
