import { categories } from './categories';
import { displayProducts } from './displayProducts';
import { editProduct } from './editProduct';
import { products } from './products';
import { removeProduct } from './removeProduct';
import { search } from './search';

export const productsModel = {
  products,
  displayProducts,
  categories,
  editProduct,
  removeProduct,
  search
};
