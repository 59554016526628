import { createEffect, createEvent, sample } from 'effector';
import localforage from 'localforage';
import { creds } from './creds';
import { LOCAL_STORAGE_MERCHANT_INFO } from 'shared/config/constants';
import { servicesModel } from 'entities/Services/model';
// import { profileModel } from 'entities/Profile/model';
// import { servicesModel } from 'entities/Services/model';
// import { productsModel } from 'entities/Products/model';
// import { configModel } from 'entities/Config/model';
// import { ordersModel } from 'entities/Orders/model';
// import { statisticsModel } from 'entities/Statistics/model';

const logoutAccount = createEvent();
const logoutAccountFx = createEffect(async () => {
  await localforage.clear();
  localStorage.removeItem(LOCAL_STORAGE_MERCHANT_INFO);
  return true;
});

sample({
  clock: logoutAccount,
  target: logoutAccountFx,
});

sample({
  clock: logoutAccountFx.doneData,
  target: [
    creds.events.resetAll,
    // servicesModel.selectedService.events.resetService,
    // productsModel.products.events.resetAll,
    // servicesModel.services.events.resetAll,
    // ordersModel.cart.events.reset,
    // ordersModel.list.events.reset,
    // statisticsModel.stats.events.reset,
  ],
});

export const logout = {
  events: {
    logoutAccount,
  },
};
