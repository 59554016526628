import i18next from 'i18next';
import { IGetTimeSuffixOptions } from './types';

export const getTimeSuffix = (
  value: number,
  options: IGetTimeSuffixOptions,
) => {
  if (!value) return '';

  const time = Math.abs(value) % 100;

  if (options?.ignoreZero && value === 0) return '';

  if (time >= 11 && time <= 19) {
    return options.type === 'hour'
      ? i18next.t('time.hours', { value })
      : i18next.t('time.minutes', { value });
  }

  switch (time % 10) {
    case 1:
      return options.type === 'hour'
        ? i18next.t('time.hour', { value })
        : i18next.t('time.minute', { value });
    case 2:
    case 3:
    case 4:
      return options.type === 'hour'
        ? i18next.t('time.hours2', { value })
        : i18next.t('time.minutes2', { value });
    default:
      return options.type === 'hour'
        ? i18next.t('time.hours', { value })
        : i18next.t('time.minutes', { value });
  }
};
