import React, { FC, useEffect, useState } from 'react';
import { Box } from '../Box';
import { workingHoursCss } from './style';
import { sharedCss } from 'shared/css';
import { TOnClick, TOnSubmit } from 'shared/types/eventHandlers';
import { dateTimePickerModalModel } from 'features/DateTimePickerModal/model';
import { Field } from '../Field';
import { Svg } from 'shared/ui/atoms/Svg';
import { Button } from 'shared/ui/atoms/Button';
import { useForm } from 'effector-forms';
import { shopModel } from 'pages/Shop/model';
import { useTranslation } from 'react-i18next';
import { useUnit } from 'effector-react';
import { TimePicker } from 'react-ios-time-picker';
import { getTimeSuffix } from 'shared/lib/getTimeSuffix';

export const WorkingHours: FC = () => {
  const { t } = useTranslation();
  const { fields, submit } = useForm(shopModel.scheduleForm.stores.$form);
  const [type, setType] = useState<string>(null);
  const isPending = useUnit(
    shopModel.scheduleForm.pendings.$isUpdateSchedulePending,
  );

  useEffect(() => {
    shopModel.scheduleForm.events.initForm();
  }, []);

  const onClickTime: TOnClick<HTMLInputElement> = (e) => {
    const name = e.currentTarget.name;
    setType(name);
  };

  const onClickDate: TOnClick<HTMLInputElement> = (e) => {
    dateTimePickerModalModel.modal.setVisible({
      isOpen: true,
      title: t('shop.workingHours.selectDays'),
      type: 'days',
      selectedDays: fields.days.value,
      onSuccess: (value) => {
        fields.days.onChange(value);
      },
    });
  };

  const onSaveTime = (val) => {
    const [hour, minute] = val.split(':');
    const isReset = hour === '00' && minute === '00';

    setType(null);

    if (isReset) {
      fields[type].onChange('');
      return;
    }

    if (type === 'cooking_time') {
      const cookingTime =
        hour !== '00'
          ? `${getTimeSuffix(Number(hour), { type: 'hour' })} ${getTimeSuffix(
              Number(minute),
              { type: 'minute' },
            )}`
          : `${getTimeSuffix(Number(minute), { type: 'minute' })}`;

      fields[type].onChange(cookingTime);
    } else {
      fields[type].onChange(val);
    }
  };

  const onCancelTime = () => {
    setType(null);
  };

  const onSubmit: TOnSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <Box
      id="workingHours"
      title={t('shop.workingHours.title')}
      contentClassName={workingHoursCss.container}>
      <form onSubmit={onSubmit}>
        <Field
          label={t('shop.workingHours.days')}
          value={fields.days.value
            .filter((day) => day.is_available)
            .map((day) => t(`daysInShort.${day.name.toLowerCase()}`))
            .toString()}
          isStatic={Boolean(
            fields.days.value.filter((day) => day.is_available).length,
          )}
          inputProps={{
            name: 'days',
            readOnly: true,
            placeholder: t('shop.workingHours.selectDaysPlaceholder'),
            onClick: onClickDate,
          }}
        />

        <div
          className={`${sharedCss.rowBetween} ${workingHoursCss.timePeriod}`}>
          <Field
            label={t('shop.workingHours.from')}
            value={fields.from.value}
            isStatic={Boolean(fields.from.value)}
            inputProps={{
              name: 'from',
              readOnly: true,
              placeholder: t('shop.workingHours.time'),
              onClick: onClickTime,
            }}
            rightContent={
              <Svg
                idIcon="icLeftChevron"
                width={16}
                height={16}
                className={workingHoursCss.selectIcon}
              />
            }
          />
          <Field
            label={t('shop.workingHours.to')}
            value={fields.to.value}
            isStatic={Boolean(fields.to.value)}
            inputProps={{
              name: 'to',
              readOnly: true,
              placeholder: t('shop.workingHours.time'),
              onClick: onClickTime,
            }}
            rightContent={
              <Svg
                idIcon="icLeftChevron"
                width={16}
                height={16}
                className={workingHoursCss.selectIcon}
              />
            }
          />
        </div>
        <Field
          label={t('shop.workingHours.preparingTime')}
          value={fields.cooking_time.value}
          isStatic={Boolean(fields.cooking_time.value)}
          inputProps={{
            name: 'cooking_time',
            readOnly: true,
            placeholder: t('shop.workingHours.preparingTimePlaceholder'),
            onClick: onClickTime,
          }}
          rightContent={
            <Svg
              idIcon="icLeftChevron"
              width={16}
              height={16}
              className={workingHoursCss.selectIcon}
            />
          }
        />
        <Button
          styleType="accent"
          type="submit"
          isLoading={isPending}
          className={workingHoursCss.submitBtn}>
          {t('save')}
        </Button>
      </form>
      {Boolean(type) && (
        <TimePicker
          isOpen
          id="loltimepicker"
          name={type}
          onSave={onSaveTime}
          onCancel={onCancelTime}
          value={type === 'cooking_time' ? '00:00' : '12:00'}
          cancelButtonText={t('cancel')}
          saveButtonText={t('apply')}
        />
      )}
    </Box>
  );
};
