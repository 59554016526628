import { createEffect, createEvent, createStore, sample } from 'effector';
import { searchProductByName } from '../api';
import {
  ISearchProductByNameParams,
  ISearchProductItem,
} from 'shared/types/api/products';
import { TSearchProductFx } from '../ui/SearchProduct/types';

const reset = createEvent();

const $result = createStore<ISearchProductItem[]>(null).reset(reset);

const searchProduct = createEvent<ISearchProductByNameParams>();
const searchProductFx = createEffect<TSearchProductFx>(searchProductByName);

const $isPending = searchProductFx.pending;

sample({
  clock: searchProduct,
  target: searchProductFx,
});

sample({
  clock: searchProductFx.doneData,
  fn: (res) => res.data.data,
  target: $result,
});

export const search = {
  stores: {
    $result,
  },
  events: {
    searchProduct,
    reset,
  },
  pendings: {
    $isPending,
  },
};
