import React, { lazy } from 'react';
import { endPoints } from 'shared/config/endPoints';
import { IRoute } from '../types';
import { Navigate } from 'react-router-dom';

const Orders = lazy(() =>
  import('pages/Orders').then(({ Orders }) => ({
    default: Orders,
  })),
);

const Order = lazy(() =>
  import('pages/Order').then(({ Order }) => ({
    default: Order,
  })),
);

export const ordersRoutes: IRoute[] = [
  {
    index: true,
    path: endPoints.ORDERS,
    element: <Orders />,
  },
  {
    path: endPoints.ORDER,
    element: <Order />,
  },
  {
    path: '*',
    element: <Navigate to={`${endPoints.ORDERS}`} />,
  },
];
