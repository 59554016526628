import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  align-items: flex-start;
  margin-bottom: 12px;
`;

const content = css`
  flex: 1;
  
  &[data-is-available='false'] {
    opacity: 0.2;
  }
`;

const image = css`
  margin-right: 6px;
  aspect-ratio: 1.5;
  min-width: 15%;
  max-width: 15%;
  margin-right: 8px;
  border-radius: 4px;
`;

const desc = css`
  flex: 1;
  margin-top: -2.5px;
  word-break: break-word;
`;

const optionsButton = css`
  min-width: 35px;
  height: 40px;
  margin-right: -12px;

  svg {
    ${theming((theme) => ({ color: theme.colors.blue }))}
  }
`;

export const productCss = {
  container,
  content,
  image,
  desc,
  optionsButton,
};
