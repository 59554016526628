import React, { FC } from 'react';
import { Text } from 'shared/ui/atoms/Text';
import { statusCss } from './style';
import { Switch } from 'shared/ui/atoms/Switch';
import { useUnit } from 'effector-react';
import { servicesModel } from 'entities/Services/model';
import { useTranslation } from 'react-i18next';

export const Status: FC = () => {
  const { t } = useTranslation();
  const service = useUnit(servicesModel.selectedService.stores.$service);

  const onToggle = (value) => {
    servicesModel.status.events.updateStatus({
      shopId: service.id,
      params: { status: value },
    });
  };

  return (
    <div className={statusCss.container}>
      <Text>{t('shop.status')}</Text>
      <Switch value={service?.status} onToggle={onToggle} />
    </div>
  );
};
