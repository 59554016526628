import React, { FC } from 'react';
import { checklistCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { Svg } from 'shared/ui/atoms/Svg';
import { sharedCss } from 'shared/css';
import { Image } from 'shared/ui/atoms/Image';
import ChecklistImg from 'shared/assets/images/Checklist.webp';
import { checklistConfig } from 'pages/Home/config';
import { Button } from 'shared/ui/atoms/Button';
import { CheckBox } from 'shared/ui/atoms/CheckBox';
import { useNavigate } from 'react-router-dom';
import { scrollToElIdModel } from 'shared/model/scrollToElId';
import { useUnit } from 'effector-react';
import { productsModel } from 'entities/Products/model';
import { SpinnerBlock } from 'shared/ui/atoms/SpinnerBlock';
import { servicesModel } from 'entities/Services/model';
import { IChecklistProps } from './types';
import { useTranslation } from 'react-i18next';

export const Checklist: FC<IChecklistProps> = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isProductsPending, selectedService, updateStatusPendingList] = useUnit(
    [
      productsModel.products.pendings.$isPending,
      servicesModel.selectedService.stores.$service,
      servicesModel.status.pendings.$listOfPendings,
    ],
  );
  const isPending = updateStatusPendingList.some(
    (shopId) => shopId === selectedService?.id,
  );
  const progress = (100 * data.filledCount) / 6;

  const onClickPoint = (point) => () => {
    scrollToElIdModel.events.scrollToElId(point.elId);
    navigate(point.redirectTo);
  };

  const onClickPublic = () => {
    servicesModel.status.events.updateStatus({
      params: { status: true },
      shopId: selectedService.id,
      isPublish: true,
    });
  };

  return (
    <div className={checklistCss.container}>
      <Text size="h2">{t('home.checklist.title')}</Text>
      <div className={checklistCss.content}>
        <div className={sharedCss.rowVerticalCenter}>
          <CircularProgressbarWithChildren
            value={progress}
            strokeWidth={10}
            styles={{ root: { width: '28px', height: '28px' } }}>
            <Svg
              idIcon="icCheckBold"
              width={12}
              height={12}
              className={checklistCss.checkIcon}
            />
          </CircularProgressbarWithChildren>
          <Text size="h3" weight="medium" className={checklistCss.headerTitle}>
            {t('home.checklist.progress')} <br /> {`${data.filledCount}/6`}
          </Text>
          <Image
            src={ChecklistImg}
            alt=""
            className={`${checklistCss.headerImg} ${
              !data.allFilled && checklistCss.headerImgAnimated
            }`}
          />
        </div>
        <div className={checklistCss.points}>
          {checklistConfig.map((point) => (
            <div key={point.elId} className={checklistCss.point}>
              {isProductsPending && point.name === 'menu' ? (
                <SpinnerBlock size={30} />
              ) : (
                <CheckBox
                  isRounded
                  isChecked={data.checklist[point.elId]}
                  name={point.elId}
                  size="sm"
                />
              )}
              <Text
                size="text"
                className={`${checklistCss.pointName} ${
                  data.checklist[point.elId] && checklistCss.pointNameActive
                }`}>
                {t(point.name)}
              </Text>
              {(!data.checklist[point.elId] ||
                (isProductsPending && point.name === 'menu')) && (
                <Button
                  styleType="secondary"
                  size="sm"
                  fontSize="11"
                  className={checklistCss.pointButton}
                  onClick={onClickPoint(point)}>
                  {t(point.actionName)}
                </Button>
              )}
            </div>
          ))}
        </div>
        {!selectedService?.status && (
          <Button
            isDisabled={!data.allFilled}
            isLoading={isPending}
            styleType="accent"
            className={checklistCss.publicButton}
            onClick={onClickPublic}>
            {t('home.checklist.sendOnModeration')}
          </Button>
        )}
      </div>
    </div>
  );
};
