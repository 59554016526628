import React, { FC, Fragment, useRef } from 'react';
import { logoCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import NoLogoSvg from 'shared/assets/icons/NoLogo.svg?svgr';
import CameraSvg from 'shared/assets/icons/Camera.svg?svgr';
import { servicesModel } from 'entities/Services/model';
import { useUnit } from 'effector-react';
import { imageCropModalModel } from 'features/ImageCropModal/model';
import { SpinnerBlock } from 'shared/ui/atoms/SpinnerBlock';
import { Image } from 'shared/ui/atoms/Image';
import { useTranslation } from 'react-i18next';
import { validateImage } from 'shared/lib/validateImage';
import { showToast } from 'shared/lib/showToast';
import { EToastType } from 'shared/types/toast';

export const Logo: FC = () => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [service, isUpdatePending] = useUnit([
    servicesModel.selectedService.stores.$service,
    servicesModel.logo.pendings.$isPending,
  ]);

  const onClick = () => {
    if (!isUpdatePending) {
      inputRef.current?.click();
      inputRef.current.onchange = async () => {
        const file = inputRef.current.files[0];
        if (file) {
          try {
            await validateImage(file);
            const src = URL.createObjectURL(file);
            imageCropModalModel.modal.setVisible({
              isOpen: true,
              src,
              cropperProps: {
                cropSize: { width: 512, height: 512 },
              },
              onDone: async (file) => {
                const formData = new FormData();
                formData.append('logo', file);
                servicesModel.logo.events.updateLogo(formData);
              },
              onClosed: () => {
                URL.revokeObjectURL(src);
                inputRef.current.value = '';
              },
            });
          } catch (err) {
            showToast({
              toastParams: {
                type: EToastType.Error,
                messageKey: err.message,
              },
            });
          }
        }
      };
    }
  };

  return (
    <div className={logoCss.container}>
      <div className={logoCss.logoBox}>
        {service?.logo ? (
          <Image className={logoCss.logo} src={service.logo} />
        ) : (
          <NoLogoSvg width={72} height={72} />
        )}
        <Text color="secondary">{t('shop.logo.requirements')}</Text>
      </div>
      <button className={logoCss.button} type="button" onClick={onClick}>
        {isUpdatePending ? (
          <SpinnerBlock size={30} />
        ) : (
          <Fragment>
            <CameraSvg width={24} height={24} />
            {t('shop.logo.title')}
          </Fragment>
        )}
      </button>
      <input ref={inputRef} hidden type="file" accept="image/*" />
    </div>
  );
};
