import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { themeModel } from 'shared/theme/model';

export const useSetTheme = () => {
  const { theme, mode } = useUnit(themeModel.stores.$theme);

  useEffect(() => {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute('content', theme.background.bg1);
    document.querySelector('html').style.backgroundColor = theme.background.bg1;
    const bodyEl = document.querySelector('body');
    bodyEl.classList.add(mode);
  }, [theme]);
};
