import { createEffect, createEvent, createStore, sample } from 'effector';
import { ordersApi } from 'entities/Orders/api';
import { IOrder } from 'shared/types/api/orders';
import { TGetOrderFx } from '../types';
import { servicesModel } from 'entities/Services/model';
import { EToastType, IShowToast } from 'shared/types/toast';
import { showToastEvent } from 'shared/model/toast';

const resetData = createEvent();
const $data = createStore<IOrder>(null).reset(resetData);

const setOrder = createEvent<IOrder>();
const getOrder = createEvent<string>();
const getOrderFx = createEffect<TGetOrderFx>(ordersApi.getOrder);

const $isPending = getOrderFx.pending;

sample({
  clock: getOrder,
  source: {
    service: servicesModel.selectedService.stores.$service,
    order: $data,
  },
  fn: ({ service }, id) => ({ shopId: service.id, id }),
  target: getOrderFx,
});

sample({
  clock: setOrder,
  target: $data,
});

sample({
  clock: getOrderFx.doneData,
  fn: (res) => res.data,
  target: $data,
});

sample({
  clock: getOrderFx.done,
  filter: ({ result }) => Boolean(result.data?.['message']),
  fn: ({ params }): IShowToast => ({
    toastParams: {
      type: EToastType.Error,
      translateParams: {
        orderId: params.id,
      },
      messageKey: `orderDetails.notFound`,
    },
  }),
  target: showToastEvent,
});

export const order = {
  stores: {
    $data,
  },
  events: {
    getOrder,
    setOrder,
    resetData,
  },
  pendings: {
    $isPending,
  },
};
