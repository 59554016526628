import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat, PatternFormat } from 'react-number-format';

import { IInput } from './types';

import { inputCss } from './style';
import { sharedCss } from 'shared/css';

export const Input: FC<IInput> = ({
  labelKey,
  errorKey,
  classInput = '',
  classLabel = '',
  classWrapper = '',
  classInputWrapper = '',
  classLeftContentWrapper = '',
  classRightContentWrapper = '',
  leftContent = null,
  rightContent = null,
  isNumber = false,
  isPattern = false,
  patternProps,
  isDefaultStyle = true,
  isAllowed,
  decimals,
  suffix,
  inputProps = {},
  thousandSeparator = ' ',
  errorRowCount = 1,
  isWithError = true,
  inputRef,
  onValueChange = () => {},
  testId,
  defaultInputNumberValue = '0.00',
}) => {
  const { t } = useTranslation();
  const renderPatterInput = () => (
    <PatternFormat
      onMouseDown={inputProps.onMouseDown}
      getInputRef={inputRef}
      className={`${isDefaultStyle ? inputCss.input : ''} ${classInput}`}
      onChange={inputProps.onChange}
      onBlur={inputProps.onBlur}
      name={inputProps.name}
      readOnly={inputProps.readOnly}
      value={inputProps.value as string}
      maxLength={inputProps.maxLength}
      placeholder={inputProps.placeholder}
      autoComplete="off"
      defaultValue={defaultInputNumberValue}
      autoFocus={inputProps.autoFocus}
      disabled={inputProps.disabled}
      data-is-error={Boolean(errorKey)}
      {...patternProps}
      onValueChange={onValueChange}
      isAllowed={isAllowed}
    />
  );
  const renderNumberInput = () => (
    <NumericFormat
      onMouseDown={inputProps.onMouseDown}
      getInputRef={inputRef}
      decimalSeparator="."
      thousandSeparator={thousandSeparator}
      className={`${isDefaultStyle ? inputCss.input : ''} ${classInput}`}
      onChange={inputProps.onChange}
      onBlur={inputProps.onBlur}
      name={inputProps.name}
      value={inputProps.value as string}
      maxLength={inputProps.maxLength}
      placeholder={inputProps.placeholder}
      autoComplete="off"
      defaultValue={defaultInputNumberValue}
      suffix={suffix}
      allowNegative={false}
      autoFocus={inputProps.autoFocus}
      disabled={inputProps.disabled}
      readOnly={Boolean(inputProps?.readOnly)}
      data-is-error={Boolean(errorKey)}
      decimalScale={decimals}
      allowedDecimalSeparators={[',']}
      onValueChange={onValueChange}
      isAllowed={isAllowed}
      onClick={inputProps?.onClick}
    />
  );
  return (
    <div className={classWrapper}>
      {labelKey && (
        <label
          className={`${inputCss.label} ${classLabel}`}
          htmlFor={inputProps?.name}>
          {t(labelKey)}
        </label>
      )}
      <div className={`${inputCss.inputWrapper} ${classInputWrapper}`}>
        {isPattern && renderPatterInput()}
        {isNumber && renderNumberInput()}
        {!isNumber && !isPattern && (
          <input
            className={`${isDefaultStyle ? inputCss.input : ''} ${classInput}`}
            {...inputProps}
            ref={inputRef}
            data-cy={testId}
            data-is-error={Boolean(errorKey)}
            placeholder={t(inputProps.placeholder)}
          />
        )}
        {leftContent && (
          <div
            className={`${inputCss.leftContentWrapper} ${classLeftContentWrapper}`}>
            {leftContent}
          </div>
        )}
        {rightContent && (
          <div
            className={`${inputCss.rightContentWrapper} ${classRightContentWrapper}`}>
            {rightContent}
          </div>
        )}
      </div>
      {isWithError && errorKey && (
        <p
          className={sharedCss.errorClass}
          data-error-row-count={errorRowCount}>
          {errorKey && t(errorKey)}
        </p>
      )}
    </div>
  );
};
