import { modalFactory } from 'shared/lib/modalFactory';
import { IImageCropModalParams } from '../types';

export const modal = modalFactory<IImageCropModalParams>({
  isOpen: false,
  src: null,
  cropperProps: null,
  onCanceled: () => null,
  onDone: () => null,
});
