import React, { FC, Fragment, useEffect } from 'react';
import { Box } from '../Box';
import { CheckBox } from 'shared/ui/atoms/CheckBox';
import { TOnChange } from 'shared/types/eventHandlers';
import { timeManagerCss } from './style';
import { TServiceDeliveryTypes } from 'shared/config/enums';
import { useTranslation } from 'react-i18next';
import { useForm } from 'effector-forms';
import { shopModel } from 'pages/Shop/model';
import { Field } from '../Field';
import { getTimeSuffix } from 'shared/lib/getTimeSuffix';
import { Button } from 'shared/ui/atoms/Button';
import { shopCss } from 'pages/Shop/style';
import { useUnit } from 'effector-react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { TimePicker } from 'features/TimePicker';
import { IServiceDeliveryInfo } from 'shared/types/api/services';

export const TimeManager: FC = () => {
  const { t } = useTranslation();
  const [isPending] = useUnit([shopModel.timeManagerForm.pendings.$isPending]);
  const modal = useModal('timePicker');
  const { values, fields, submit } = useForm(
    shopModel.timeManagerForm.stores.$form,
  );

  useEffect(() => {
    shopModel.timeManagerForm.events.initForm();
  }, []);

  const onChange: TOnChange = (e) => {
    const name = e.target.name as TServiceDeliveryTypes;
    fields[name].onChange({ ...values[name], status: !values[name].status });
  };

  const onOpenTimePickers =
    (type: TServiceDeliveryTypes, field: keyof IServiceDeliveryInfo) => () => {
      modal.show({
        title: t(`timeManager.${field}`),
        contentProps: {
          disableDrag: true,
        },
        content: (
          <TimePicker
            value={values[type].value?.[field]}
            onDone={(value) => {
              fields[type].onChange({
                ...values[type],
                value: { ...values[type].value, [field]: value },
              });
              modal.hide();
            }}
          />
        ),
      });
    };

  const onSave = () => {
    submit();
  };

  return (
    <Box
      id="timeManager"
      title={t(`timeManager.title`)}
      contentClassName={timeManagerCss.content}>
      <div className={timeManagerCss.optionContainer}>
        <div className={timeManagerCss.option}>
          <CheckBox
            name={'pickup_enabled'}
            wrapperClassName={timeManagerCss.checkbox}
            labelKey={t(`timeManager.pickup_enabled`)}
            isChecked={values.pickup_enabled.status}
            onChange={onChange}
          />
        </div>
        {values.pickup_enabled?.status && (
          <Fragment>
            <Field
              label={t(`timeManager.fromLabel`)}
              value={values.pickup_enabled.value?.from}
              isStatic={true}
              inputProps={{
                readOnly: true,
              }}
              onClick={onOpenTimePickers('pickup_enabled', 'from')}
            />
            <Field
              label={t(`timeManager.toLabel`)}
              value={values.pickup_enabled.value?.to}
              isStatic={true}
              inputProps={{
                readOnly: true,
              }}
              onClick={onOpenTimePickers('pickup_enabled', 'to')}
            />
          </Fragment>
        )}
      </div>
      <div className={timeManagerCss.optionContainer}>
        <div className={timeManagerCss.option}>
          <CheckBox
            name={'delivery_enabled'}
            wrapperClassName={timeManagerCss.checkbox}
            labelKey={t(`timeManager.delivery_enabled`)}
            isChecked={values.delivery_enabled.status}
            onChange={onChange}
          />
        </div>
        {values.delivery_enabled?.status && (
          <Fragment>
            <Field
              label={t(`timeManager.fromLabel`)}
              value={values.delivery_enabled.value?.from}
              isStatic={true}
              inputProps={{
                readOnly: true,
              }}
              onClick={onOpenTimePickers('delivery_enabled', 'from')}
            />
            <Field
              label={t(`timeManager.toLabel`)}
              value={values.delivery_enabled.value?.to}
              isStatic={true}
              inputProps={{
                readOnly: true,
              }}
              onClick={onOpenTimePickers('delivery_enabled', 'to')}
            />
            <Field
              label={t('timeManager.averageDelivery')}
              value={`${getTimeSuffix(
                Number(
                  values.delivery_enabled.value?.offset_time?.split(':')?.[0],
                ),
                { type: 'hour', ignoreZero: true },
              )} ${getTimeSuffix(
                Number(
                  values.delivery_enabled.value?.offset_time?.split(':')?.[1],
                ),
                { type: 'minute', ignoreZero: true },
              )}`}
              isStatic={true}
              inputProps={{
                readOnly: true,
              }}
              onClick={onOpenTimePickers('delivery_enabled', 'offset_time')}
            />
            <Field
              label={t('timeManager.intervalDelivery')}
              value={`${getTimeSuffix(
                Number(
                  values.delivery_enabled.value?.interval?.split(':')?.[0],
                ),
                { type: 'hour', ignoreZero: true },
              )} ${getTimeSuffix(
                Number(
                  values.delivery_enabled.value?.interval?.split(':')?.[1],
                ),
                { type: 'minute', ignoreZero: true },
              )}`}
              isStatic={true}
              inputProps={{
                readOnly: true,
              }}
              onClick={onOpenTimePickers('delivery_enabled', 'interval')}
            />
          </Fragment>
        )}
      </div>
      <Button
        styleType="accent"
        type="submit"
        className={shopCss.saveBtn}
        isLoading={isPending}
        onClick={onSave}>
        {t('save')}
      </Button>
    </Box>
  );
};
