import React, { Suspense } from 'react';
import { IRoute } from 'processes/Router/types';
import { SpinnerBlock } from 'shared/ui/atoms/SpinnerBlock';
import { Route } from 'react-router-dom';

export const renderRoutes = (routes: IRoute[], parentKey = '') =>
  routes.map((route) => (
    <Route
      key={`${parentKey ? `${parentKey}_${route.path}` : route.path}`}
      element={
        <Suspense fallback={<SpinnerBlock size={40} />}>
          {route.element}
        </Suspense>
      }
      {...(route.path && { path: route.path })}
      {...(route?.children && {
        children: renderRoutes(route.children, route.path),
      })}
      {...(route?.index && { index: route.index })}
    />
  ));
