import React, { FC, Fragment } from 'react';
import { productsCss } from './style';
import { IProductsProps } from './types';
import { Product } from '../Product';
import { Button } from 'shared/ui/atoms/Button';
import { createProductModalModel } from 'features/CreateProductModal/model';
import { useTranslation } from 'react-i18next';

export const Products: FC<IProductsProps> = ({ data }) => {
  const { t } = useTranslation();

  const onAddProduct = () => {
    createProductModalModel.modal.setVisible({
      isOpen: true,
      categoryId: data.category_id,
    });
  };

  return (
    <div className={productsCss.container}>
      {data?.products?.map((product, index) => (
        <Fragment key={product.id}>
          <Product data={product} />
          {index < data.products.length - 1 && (
            <div className={productsCss.divider} />
          )}
        </Fragment>
      ))}
      <Button
        styleType="secondary"
        fontSize="14"
        className={productsCss.addButton}
        onClick={onAddProduct}>
        {t('menu.addPosition')}
      </Button>
    </div>
  );
};
