import {
  CREATE_SERVICE,
  GET_BUSINESS_SERVICES,
  GET_SERVICES,
  UPDATE_SERVICE,
  UPDATE_SERVICE_ADDRESS,
  UPDATE_SERVICE_BACKGROUND,
  UPDATE_SERVICE_CHANNEL,
  UPDATE_SERVICE_DELIVERY_INFO,
  UPDATE_SERVICE_DELIVERY_TYPE,
  UPDATE_SERVICE_LOGO,
  UPDATE_SERVICE_PICKUP_INFO,
  UPDATE_SERVICE_PREVIEW_TEXT,
  UPDATE_SERVICE_SCHEDULE,
  UPDATE_SERVICE_STATUS,
} from 'shared/api/endpoints';
import { API, FORM_DATA } from 'shared/api/executor';
import {
  TCreateService,
  TFetchBusinessServices,
  TGetServices,
  TUpdateService,
  TUpdateServiceAddress,
  TUpdateServiceBackground,
  TUpdateServiceChannel,
  TUpdateServiceDeliveryInfo,
  TUpdateServiceDeliveryType,
  TUpdateServiceLogo,
  TUpdateServicePickupInfo,
  TUpdateServicePreviewText,
  TUpdateServiceSchedule,
  TUpdateServiceStatus,
} from '../types';

const fetchBusinessServices: TFetchBusinessServices = () =>
  API().get(GET_BUSINESS_SERVICES);

const getServices: TGetServices = () => API().get(GET_SERVICES);

const createService: TCreateService = (params) =>
  API().post(CREATE_SERVICE, params);

const updateServiceAddress: TUpdateServiceAddress = ({ shopId, params }) =>
  API().post(UPDATE_SERVICE_ADDRESS(shopId), params);

const updateServiceChannel: TUpdateServiceChannel = ({ shopId, params }) =>
  API().post(UPDATE_SERVICE_CHANNEL(shopId), params);

const updateServicePreviewText: TUpdateServicePreviewText = ({
  shopId,
  params,
}) => API().post(UPDATE_SERVICE_PREVIEW_TEXT(shopId), params);

const updateServiceStatus: TUpdateServiceStatus = ({ shopId, params }) =>
  API().post(UPDATE_SERVICE_STATUS(shopId), params);

const updateServiceBackground: TUpdateServiceBackground = ({
  shopId,
  params,
}) =>
  API().post(UPDATE_SERVICE_BACKGROUND(shopId), params, {
    headers: { 'Content-Type': FORM_DATA },
  });

const updateServiceLogo: TUpdateServiceLogo = ({ shopId, params }) =>
  API().post(UPDATE_SERVICE_LOGO(shopId), params, {
    headers: { 'Content-Type': FORM_DATA },
  });

const updateServiceSchedule: TUpdateServiceSchedule = ({ shopId, params }) =>
  API().post(UPDATE_SERVICE_SCHEDULE(shopId), params);

const updateService: TUpdateService = (params) =>
  API().post(UPDATE_SERVICE(params.id), params);

const updateServiceDeliveryType: TUpdateServiceDeliveryType = ({
  shopId,
  params,
}) => API().post(UPDATE_SERVICE_DELIVERY_TYPE(shopId), params);

const updateServiceDeliveryInfo: TUpdateServiceDeliveryInfo = ({
  shopId,
  params,
}) => API().post(UPDATE_SERVICE_DELIVERY_INFO(shopId), params);

const updateServicePickupInfo: TUpdateServicePickupInfo = ({
  shopId,
  params,
}) => API().post(UPDATE_SERVICE_PICKUP_INFO(shopId), params);

export const servicesApi = {
  fetchBusinessServices,
  getServices,
  createService,
  updateServiceAddress,
  updateServiceChannel,
  updateServicePreviewText,
  updateServiceStatus,
  updateServiceBackground,
  updateServiceLogo,
  updateServiceSchedule,
  updateService,
  updateServiceDeliveryType,
  updateServiceDeliveryInfo,
  updateServicePickupInfo,
};
