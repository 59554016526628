import i18next from 'i18next';
import { IValidateImageParams } from './types';

export const validateImage = (
  file: File,
  params: IValidateImageParams = { minWidth: 512, minHeight: 512 },
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const fileSize = file.size; // Size in bytes
    const maxFileSize = 2 * 1024 * 1024; // 2 MB

    if (fileSize > maxFileSize) {
      reject(new Error(i18next.t('errors.minImgSize')));
    }

    const img = new Image();

    img.onload = function () {
      const width = img.width;
      const height = img.height;
      if (width < params.minWidth || height < params.minHeight) {
        reject(
          new Error(
            i18next.t('errors.minImgDimensions', {
              minWidth: params.minWidth,
              minHeight: params.minHeight,
            }),
          ),
        );
      }
      resolve(true);
    };

    img.onerror = function () {
      reject(new Error(i18next.t('errors.imgLoadingFailed')));
    };

    img.src = URL.createObjectURL(file); // This triggers the image loading
  });
};
