import React, { ReactElement, useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Sheet, SheetContentProps } from 'react-modal-sheet';
import { clsx } from 'shared/lib/clsx';
import { bottomSheetStyles } from './style';
import { Text } from 'shared/ui/atoms/Text';

export interface IBottomSheetProps {
  classNames?: {
    container?: string;
    header?: string;
    content?: string;
    backdrop?: string;
  };
  name?: string;
  header?: ReactElement;
  title?: string;
  content?: ReactElement;
  detent?: 'full-height' | 'content-height';
  contentProps?: SheetContentProps;
  onClose: () => void;
}

export default NiceModal.create(
  ({
    classNames,
    name = null,
    header = null,
    title = '',
    content = null,
    detent = 'content-height',
    contentProps,
    onClose,
  }: IBottomSheetProps) => {
    const modal = useModal(name);
    const [open, setOpen] = useState(false);
    const hasHeader = header || title;

    useEffect(() => {
      setOpen(modal.visible);
      onClose?.();
    }, [modal.visible]);

    const onHide = () => {
      setOpen(false);
      modal.hide();
    };

    return (
      <Sheet
        isOpen={open}
        dragCloseThreshold={0.2}
        detent={detent}
        onClose={onHide}
        onCloseEnd={() => modal.remove()}>
        <Sheet.Container
          className={clsx(bottomSheetStyles.container, classNames?.container)}>
          <Sheet.Header
            className={clsx(bottomSheetStyles.header, classNames?.header)}>
            {hasHeader &&
              (header ?? (
                <div className={bottomSheetStyles.titleContainer}>
                  <Text size="h2">{title}</Text>
                </div>
              ))}
          </Sheet.Header>

          <Sheet.Content
            {...contentProps}
            className={clsx(bottomSheetStyles.content, classNames?.content)}>
            {content}
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onHide} className={clsx(classNames?.backdrop)} />
      </Sheet>
    );
  },
);
