import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  border-radius: 32px 32px 0 0 !important;
  ${theming((theme) => ({
    backgroundColor: `${theme.background.bg1} !important`,
  }))}
`;

const header = css`
  padding: 21px 16px 0px 16px;

  &:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 50%;
    width: 48px;
    border-radius: 50px;
    height: 5px;
    transform: translateX(-50%);

    ${theming((theme) => ({
      backgroundColor: theme.colors.blue,
    }))}
  }

  .react-modal-sheet-header {
    display: none !important;
  }
`;

const titleContainer = css`
  padding-top: 20px;
  padding-bottom: 24px;
`;

const content = css`
  display: flex;
  flex-direction: column;
`;

export const bottomSheetStyles = {
  container,
  header,
  titleContainer,
  content,
};
