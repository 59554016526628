import React, { FC, Fragment, useEffect } from 'react';
import { Box } from '../Box';
import { telegramChannelCss } from './style';
import { Input } from 'shared/ui/atoms/Input';
import { Button } from 'shared/ui/atoms/Button';
import { shopModel } from 'pages/Shop/model';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-forms';
import { TOnChange, TOnSubmit } from 'shared/types/eventHandlers';
import { shopCss } from 'pages/Shop/style';
import { TelegramInstructionModal } from '../TelegramInstructionModal';
import { useTranslation } from 'react-i18next';

export const TalegramChannel: FC = () => {
  const { t } = useTranslation();
  const [isPending] = useUnit([shopModel.telegramForm.pendings.$isPending]);
  const { fields, errorText, submit } = useForm(
    shopModel.telegramForm.stores.$form,
  );

  useEffect(() => {
    shopModel.telegramForm.events.initForm();
  }, []);

  const onChange: TOnChange = (e) => {
    fields[e.target.name].onChange(e.target.value);
  };

  const onClickIntruction = () => {
    shopModel.telegramForm.tgModalFactory.setVisible(true);
  };

  const onSubmit: TOnSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <Fragment>
      <Box
        id="telegramChannel"
        title={t('shop.telegram.title')}
        contentClassName={telegramChannelCss.container}
        headerActionTitle={t('shop.telegram.headerTitle')}
        onClickHeaderAction={onClickIntruction}>
        <form onSubmit={onSubmit}>
          <Input
            inputProps={{
              placeholder: '@channelusername',
              name: 'tg_channel_id',
              value: fields.tg_channel_id.value,
              onChange,
            }}
            errorKey={errorText('tg_channel_id')}
          />
          <Button
            styleType="accent"
            isDisabled={!fields.tg_channel_id.value}
            type="submit"
            className={shopCss.saveBtn}
            isLoading={isPending}>
            {t('save')}
          </Button>
        </form>
      </Box>
      <TelegramInstructionModal />
    </Fragment>
  );
};
