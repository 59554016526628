import { useUnit } from 'effector-react';
import { ordersModel } from 'entities/Orders/model';
import { productsModel } from 'entities/Products/model';
import { servicesModel } from 'entities/Services/model';
import { statisticsModel } from 'entities/Statistics/model';
import { useEffect } from 'react';

export const useGetServiceData = () => {
  const selectedService = useUnit(
    servicesModel.selectedService.stores.$service,
  );

  useEffect(() => {
    if (selectedService) {
      productsModel.categories.events.getCategories();
      productsModel.products.events.getProducts();
      statisticsModel.stats.events.getStats();
      ordersModel.list.events.getOrders();
    }
  }, []);
};
