import { shopCss } from 'pages/Shop/style';
import { useEffect } from 'react';
import { scrollToElIdModel } from 'shared/model/scrollToElId';

export const useScrollToElementId = (elId: string) => {
  useEffect(() => {
    if (elId) {
      const el = document.getElementById(elId);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        el.classList.add(shopCss.boxAccentAnimation);
        setTimeout(() => {
          el.classList.remove(shopCss.boxAccentAnimation);
          scrollToElIdModel.events.resetElId();
        }, 3000);
      }
    }
  }, [elId]);
};
