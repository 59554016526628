import React, { FC, useState } from 'react';
import { availableStatsCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { Svg } from 'shared/ui/atoms/Svg';
import { useUnit } from 'effector-react';
import { statisticsModel } from 'entities/Statistics/model';
import BigNumber from 'bignumber.js';
import { shortDays } from 'shared/config/constants';
import { nFormatter } from 'shared/lib/nFormatter';
import { EShortDays } from 'shared/config/enums';
import { useTranslation } from 'react-i18next';

export const AvailableStats: FC = () => {
  const { t } = useTranslation();
  const [stats] = useUnit([statisticsModel.stats.stores.$data]);
  const [activeDayIndex, setActiveDayIndex] = useState<number>(null);

  const orders = stats.orders.reduce((prev, acc) => prev + acc.count, 0);
  const views = stats.views.reduce((prev, acc) => prev + acc.count, 0);
  const totalProfit = stats.revenue.reduce((prev, acc) => prev + acc.count, 0);
  const profitByDays = stats.revenue.reduce<number[]>(
    (prev, acc) => [...prev, acc.count],
    [],
  );
  const maxProfit = Math.max(...profitByDays);
  const profit = stats.revenue?.[activeDayIndex]?.count || totalProfit;

  const onClickDay = (index: number) => () => {
    setActiveDayIndex(index === activeDayIndex ? null : index);
  };

  return (
    <div className={availableStatsCss.container}>
      <div className={availableStatsCss.row}>
        <div className={availableStatsCss.box}>
          <div className={availableStatsCss.boxHeader}>
            <Text size="h3">{t('home.stats.views')}</Text>
            <Svg
              idIcon="icView"
              width={24}
              height={24}
              className={availableStatsCss.blueIcon}
            />
          </div>
          <Text size="h3" weight="medium">
            {views}
          </Text>
        </div>
        <div className={availableStatsCss.box}>
          <div className={availableStatsCss.boxHeader}>
            <Text size="h3">{t('home.stats.orders')}</Text>
            <Svg
              idIcon="icDots"
              width={24}
              height={24}
              className={availableStatsCss.blueIcon}
            />
          </div>
          <Text size="h3" weight="medium">
            {orders}
          </Text>
        </div>
      </div>
      <div className={availableStatsCss.box}>
        <div className={availableStatsCss.boxHeader}>
          <Text size="h3">
            {t('home.stats.profit', {
              value: shortDays?.[activeDayIndex] || t('home.stats.week'),
            })}
          </Text>
          <Svg
            idIcon="icGrowth"
            width={24}
            height={24}
            className={availableStatsCss.greenIcon}
          />
        </div>
        <Text size="h3" weight="medium">{`${BigNumber(profit).toFormat()} ${t(
          'sum',
        )}`}</Text>
        <div className={availableStatsCss.profitStats}>
          {stats.revenue.map((data, index) => (
            <div
              key={data.day}
              className={availableStatsCss.profitColumn}
              onClick={onClickDay(index)}>
              <div
                className={availableStatsCss.profitIndicator}
                data-is-active={index === activeDayIndex}
                {...(Boolean(maxProfit) && {
                  style: { height: (data.count * 100) / maxProfit },
                })}
              />
              <Text size="span" color="secondary" weight="bold">
                {nFormatter(data.count)}
              </Text>
              <Text size="span" color="secondary" weight="medium">
                {t(`daysInShort.${data.day.toLowerCase()}`)}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
