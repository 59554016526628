import { css } from '@linaria/core';
import { theming } from 'shared/theme';
import { setAlpha } from 'shared/theme/lib/setAlpha';

const container = css`
  form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

const boxOffice = css`
  border-radius: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;

  ${theming((theme) => ({ backgroundColor: theme.background.bg1 }))}
`;

const boxOfficeHeaderContent = css`
  gap: 8px;
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const boxOfficeStatusDot = css`
  min-width: 8px;
  height: 8px;
  border-radius: 50px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    left: 2px;
    bottom: 2px;
    border-radius: 50px;
  }
`;

const selectedBoxOffice = css`
  margin-top: 6px;
  word-break: break-word;
`;

const boxOfficeStatus = css`
  border-radius: 40px;
  padding: 2.5px 5px;

  ${theming((theme) => ({ backgroundColor: theme.background.bg2 }))}

  span {
    font-size: 11px;
    margin-left: 4px;
  }

  &[data-is-active='true'] {
    span {
      ${theming((theme) => ({ color: theme.colors.green }))}
    }

    .${boxOfficeStatusDot} {
      ${theming((theme) => ({
        backgroundColor: setAlpha(theme.colors.green, 0.2),
      }))}

      &:after {
        ${theming((theme) => ({ backgroundColor: theme.colors.green }))}
      }
    }
  }

  &[data-is-active='false'] {
    span {
      ${theming((theme) => ({ color: theme.colors.red }))}
    }

    .${boxOfficeStatusDot} {
      ${theming((theme) => ({
        backgroundColor: setAlpha(theme.colors.red, 0.2),
      }))}

      &:after {
        ${theming((theme) => ({ backgroundColor: theme.colors.red }))}
      }
    }
  }
`;

export const baseInfoCss = {
  container,
  boxOffice,
  boxOfficeHeaderContent,
  boxOfficeStatus,
  boxOfficeStatusDot,
  selectedBoxOffice,
};
