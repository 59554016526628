import { GET_JWT_TOKEN } from 'shared/api/endpoints';
import { API } from 'shared/api/executor';
import { LOCAL_STORAGE_CREDENTIALS } from 'shared/config/constants';
import { TFetchJwtToken } from '../types';

const fetchJwtToken: TFetchJwtToken = (merchant_id) => {
  const credentials = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_CREDENTIALS) ?? null,
  );

  return API().post(
    GET_JWT_TOKEN,
    { merchant_id },
    {
      headers: {
        Authorization:
          process.env.NODE_ENV === 'production'
            ? credentials?.session ?? 'dev_user'
            : 'dev_user',
      },
    },
  );
};

export const authApi = {
  fetchJwtToken,
};
