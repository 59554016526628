import { endPoints } from 'shared/config/endPoints';
import { ITab } from '../types';

export const tabs: ITab[] = [
  {
    endPoint: endPoints.DEFAULT_PAGE,
    idIcon: 'icEats',
    title: 'bottomTabs.home',
  },
  {
    endPoint: endPoints.SHOP,
    idIcon: 'icShop',
    title: 'bottomTabs.service',
  },
  {
    endPoint: endPoints.ORDERS,
    idIcon: 'icOrders',
    title: 'bottomTabs.orders',
  },
  {
    endPoint: endPoints.MENU,
    idIcon: 'icMenu',
    title: 'bottomTabs.menu',
  },
];
