import React, { FC } from 'react';
import { PageContainer } from 'shared/ui/molecules/PageContainer';
import { Header } from 'widgets/Header';
import { menuCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { Button } from 'shared/ui/atoms/Button';
import { Svg } from 'shared/ui/atoms/Svg';
import { useUnit } from 'effector-react';
import { productsModel } from 'entities/Products/model';
import { v4 } from 'uuid';
import { Category } from './ui/Category';
import { Import } from './ui/Import';
import { useTranslation } from 'react-i18next';

export const Menu: FC = () => {
  const { t } = useTranslation();
  const [products, categories] = useUnit([
    productsModel.displayProducts.stores.$data,
    productsModel.categories.stores.$categories,
  ]);
  const emptyCategory = categories.find(
    (category) => category.isNew && !category.name?.length,
  );

  const onCreateCategory = () => {
    if (!emptyCategory) {
      productsModel.categories.events.pushCategory({
        id: v4(),
        name: '',
        isNew: true,
      });
    } else {
      const el = document.getElementById(`category_field_${emptyCategory.id}`);
      el && el?.focus();
    }
  };

  return (
    <PageContainer className={menuCss.container}>
      <Header scrollEl="shopSettins" hasClose={false} title={t('menu.title')} />
      <div id="shopSettins" className={menuCss.list}>
        <Text size="h3" className={menuCss.listTitle}>
          {t('menu.createCategory')}
        </Text>
        {products.map((productsByCategory) => (
          <Category
            key={productsByCategory.category_id}
            data={productsByCategory}
          />
        ))}
        <div className={menuCss.addNewCategory}>
          <Button
            styleType="secondary"
            fontSize="14"
            onClick={onCreateCategory}>
            <Svg idIcon="icPlus" width={12} height={12} />
            {t('menu.addNewCategory')}
          </Button>
        </div>
        <Import />
      </div>
    </PageContainer>
  );
};
