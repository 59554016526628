import React, { FC, Fragment } from 'react';
import { sharedCss } from 'shared/css';
import { Svg } from 'shared/ui/atoms/Svg';
import { productCss } from './style';
import { Image } from 'shared/ui/atoms/Image';
import { IProductProps } from './types';
import { Text } from 'shared/ui/atoms/Text';
import BigNumber from 'bignumber.js';
import { Item, Menu, Separator, useContextMenu } from 'react-contexify';
import { TOnClick } from 'shared/types/eventHandlers';
import { createProductModalModel } from 'features/CreateProductModal/model';
import { productsModel } from 'entities/Products/model';
import { useStoreMap } from 'effector-react';
import { SpinnerBlock } from 'shared/ui/atoms/SpinnerBlock';
import { confirmModalModel } from 'features/ConfirmModal/model';
import { ContextMenu } from 'shared/ui/molecules/ContextMenu';
import { useTranslation } from 'react-i18next';

export const Product: FC<IProductProps> = ({ data }) => {
  const { t } = useTranslation();
  const contentMenuId = `product_${data.id}`;
  const { show } = useContextMenu({
    id: contentMenuId,
  });
  const isDeletePending = useStoreMap({
    keys: [data.id],
    store: productsModel.removeProduct.pendings.$pendingIds,
    fn: (pendings, [productId]) =>
      pendings.some((pendingId) => pendingId === productId),
  });
  const isUpdatePending = useStoreMap({
    keys: [data.id],
    store: productsModel.editProduct.pendings.$pendingIds,
    fn: (pendings, [productId]) =>
      pendings.some((pendingId) => pendingId === productId),
  });
  const isPending = isDeletePending || isUpdatePending;

  const onClickOptions: TOnClick<HTMLButtonElement> = (event) => {
    show({ event });
  };

  const onEdit = () => {
    createProductModalModel.modal.setVisible({
      isOpen: true,
      categoryId: data.category_id,
      product: data,
    });
  };

  const onStartStop = () => {
    productsModel.editProduct.events.updateStatus({
      shopId: data.service_id,
      productId: data.id,
      params: {
        is_available: !data?.is_available,
      },
    });
  };

  const onDelete = () => {
    confirmModalModel.modal.setVisible({
      isOpen: true,
      params: {
        title: t('menu.deletePosition'),
        onConfirm: () => {
          productsModel.removeProduct.events.remove(data.id);
          confirmModalModel.modal.resetModal();
        },
      },
    });
  };

  return (
    <Fragment>
      <div className={`${sharedCss.rowVerticalCenter} ${productCss.container}`}>
        <div
          className={`${sharedCss.rowVerticalCenter} ${productCss.content}`}
          data-is-available={data?.is_available}>
          {data?.image && (
            <Image src={data?.image} className={productCss.image} fit="cover" />
          )}
          <div className={productCss.desc}>
            <Text weight="semibold">{data.name}</Text>
            <Text color="secondary">{`${BigNumber(data.price).toFormat()} ${t(
              'sum',
            )}`}</Text>
          </div>
        </div>
        <button
          className={`${productCss.optionsButton} ${sharedCss.rowCenter}`}
          disabled={isPending}
          onClick={onClickOptions}>
          {isPending ? (
            <SpinnerBlock size={16} />
          ) : (
            <Svg idIcon="icOptions" width={16} height={16} />
          )}
        </button>
      </div>
      <ContextMenu id={contentMenuId}>
        <Item id="rename" onClick={onEdit}>
          <Text size="h3" weight="medium">
            {t('edit')}
          </Text>
          <Svg
            idIcon="icEdit"
            width={18}
            height={18}
            className={sharedCss.colorBlue}
          />
        </Item>
        <Separator />
        <Item id="rename" onClick={onStartStop}>
          <Text size="h3" weight="medium">
            {t(`menu.${data?.is_available ? 'stop' : 'start'}`)}
          </Text>
          <Svg
            idIcon="icStop"
            width={18}
            height={18}
            className={sharedCss.colorBlue}
          />
        </Item>
        <Separator />
        <Item id="delete" onClick={onDelete}>
          <Text size="h3" weight="medium" className={sharedCss.colorRed}>
            {t('delete')}
          </Text>
          <Svg
            idIcon="icTrash"
            width={18}
            height={18}
            className={sharedCss.colorRed}
          />
        </Item>
      </ContextMenu>
    </Fragment>
  );
};
