import { createEffect, createEvent, sample } from 'effector';
import { IService } from 'shared/types/api/services';
import { EToastType, IShowToast } from 'shared/types/toast';
import { showToastEvent } from 'shared/model/toast';
import { TUpdateServiceFx } from 'entities/Services/types';
import { servicesApi } from 'entities/Services/api';
import { selectedService } from 'entities/Services/model/selectedService';
import { services } from 'entities/Services/model/services';

export const updateServiceFactory = () => {
  const updateService = createEvent<IService>();
  const updateServiceFx = createEffect<TUpdateServiceFx>(
    servicesApi.updateService,
  );

  const $isPending = updateServiceFx.pending;

  sample({
    clock: updateService,
    target: updateServiceFx,
  });

  sample({
    clock: updateServiceFx.doneData,
    fn: (res) => res.data,
    target: [
      selectedService.events.updateService,
      services.events.updateService,
    ],
  });

  sample({
    clock: updateServiceFx.doneData,
    fn: (): IShowToast => ({
      toastParams: {
        type: EToastType.Success,
        messageKey: 'toast.serviceUpdated',
      },
    }),
    target: showToastEvent,
  });

  return {
    updateService,
    updateServiceFx,
    $isPending,
  };
};
