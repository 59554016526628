import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import { shopModel } from 'pages/Shop/model';
import { CustomModal } from 'shared/ui/atoms/CustomModal';
import { Text } from 'shared/ui/atoms/Text';
import { telegramInstructionModalCss } from './style';
import { Button } from 'shared/ui/atoms/Button';
import { Svg } from 'shared/ui/atoms/Svg';
import CopyIcon from 'shared/assets/icons/Copy.svg?svgr';
import { showToast } from 'shared/lib/showToast';
import { EToastType } from 'shared/types/toast';
import { Trans, useTranslation } from 'react-i18next';

export const TelegramInstructionModal: FC = () => {
  const { t } = useTranslation();
  const [isOpen] = useUnit([shopModel.telegramForm.tgModalFactory.$store]);

  const onClose = () => {
    shopModel.telegramForm.tgModalFactory.resetModal();
  };

  const onCopy = () => {
    navigator.clipboard.writeText('@notify_clickbot');
    showToast({
      className: telegramInstructionModalCss.toast,
      toastParams: {
        type: EToastType.Success,
        messageKey: t('copiedToClipboard'),
      },
    });
  };

  return (
    <CustomModal
      className={telegramInstructionModalCss.modal}
      isOpen={isOpen}
      onRequestClose={onClose}>
      <div className={telegramInstructionModalCss.header}>
        <Text size="h2">{t('shop.telegramModal.instruction')}</Text>
        <Button styleType="secondary" isRounded onClick={onClose}>
          <Svg idIcon="icCross" width={24} height={24} />
        </Button>
      </div>
      <div className={telegramInstructionModalCss.steps}>
        <div className={telegramInstructionModalCss.step}>
          <Text
            className={telegramInstructionModalCss.stepTitle}
            color="secondary">
            {t('shop.telegramModal.stepOne')}
          </Text>
          <Text>
            <Trans
              i18nKey="shop.telegramModal.instruction"
              components={{ br: <br />, b: <b /> }}
            />
          </Text>
        </div>
        <div className={telegramInstructionModalCss.step}>
          <Text
            className={telegramInstructionModalCss.stepTitle}
            color="secondary">
            {t('shop.telegramModal.stepTwo')}
          </Text>
          <Text>
            <Trans
              i18nKey="shop.telegramModal.stepTwoValue"
              components={{
                bot: (
                  <b
                    className={telegramInstructionModalCss.channel}
                    onClick={onCopy}>
                    @notify_clickbot <CopyIcon width={16} height={16} />
                  </b>
                ),
              }}
            />
          </Text>
        </div>
        <div className={telegramInstructionModalCss.step}>
          <Text
            className={telegramInstructionModalCss.stepTitle}
            color="secondary">
            {t('shop.telegramModal.stepThree')}
          </Text>
          <Text>
            <Trans
              i18nKey="shop.telegramModal.stepThreeValue"
              components={{ bold: <b /> }}
            />
          </Text>
        </div>
      </div>
    </CustomModal>
  );
};
