import React, { FC, Fragment, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { useRoute } from 'shared/lib/useRoute';
import { LazyComponent } from 'shared/ui/molecules/LazyComponent';
import { confirmModalModel } from 'features/ConfirmModal/model';
import { dateTimePickerModalModel } from 'features/DateTimePickerModal/model';
import { mapModalModel } from 'features/MapModal/model';
import { createProductModalModel } from 'features/CreateProductModal/model';
import { useGetServiceData } from './lib/useGetServiceData';
import { rulesModalModel } from 'features/RulesModal/model';
import NiceModal from '@ebay/nice-modal-react';
import BottomSheet from 'shared/ui/modals/BottomSheet';
import { imageCropModalModel } from 'features/ImageCropModal/model';
import { applicationFormModalModel } from 'features/ApplicationFormModal/model';
import { infoModalModel } from 'features/InfoModal/model';

NiceModal.register('optionsModal', BottomSheet);
NiceModal.register('searchProduct', BottomSheet);
NiceModal.register('timePicker', BottomSheet);

const InfoModal = lazy(() =>
  import('features/InfoModal').then(({ InfoModal }) => ({
    default: InfoModal,
  })),
);

const ImageCropModal = lazy(() =>
  import('features/ImageCropModal').then(({ ImageCropModal }) => ({
    default: ImageCropModal,
  })),
);

const ConfirmModal = lazy(() =>
  import('features/ConfirmModal').then(({ ConfirmModal }) => ({
    default: ConfirmModal,
  })),
);

const DateTimePickerModal = lazy(() =>
  import('features/DateTimePickerModal').then(({ DateTimePickerModal }) => ({
    default: DateTimePickerModal,
  })),
);

const MapModal = lazy(() =>
  import('features/MapModal').then(({ MapModal }) => ({
    default: MapModal,
  })),
);

const CreateProductModal = lazy(() =>
  import('features/CreateProductModal').then(({ CreateProductModal }) => ({
    default: CreateProductModal,
  })),
);

const RulesModal = lazy(() =>
  import('features/RulesModal').then(({ RulesModal }) => ({
    default: RulesModal,
  })),
);

const ApplicationFormModal = lazy(() =>
  import('features/ApplicationFormModal').then(({ ApplicationFormModal }) => ({
    default: ApplicationFormModal,
  })),
);

export const RouterRoutesElement: FC = () => {
  useRoute();
  useGetServiceData();
  return (
    <Fragment>
      <Outlet />
      <LazyComponent
        $store={applicationFormModalModel.modal.$store}
        withAnimation
        component={ApplicationFormModal}
      />
      <LazyComponent
        $store={confirmModalModel.modal.$store}
        withAnimation
        component={ConfirmModal}
      />
      <LazyComponent
        $store={dateTimePickerModalModel.modal.$store}
        withAnimation
        component={DateTimePickerModal}
      />
      <LazyComponent
        $store={mapModalModel.modal.$store}
        withAnimation
        component={MapModal}
      />
      <LazyComponent
        $store={createProductModalModel.modal.$store}
        withAnimation
        component={CreateProductModal}
      />
      <LazyComponent
        $store={rulesModalModel.modal.$store}
        withAnimation
        component={RulesModal}
      />
      <LazyComponent
        $store={imageCropModalModel.modal.$store}
        withAnimation
        component={ImageCropModal}
      />
      <LazyComponent
        $store={infoModalModel.modal.$store}
        withAnimation
        component={InfoModal}
      />
    </Fragment>
  );
};
