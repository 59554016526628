import React, { Fragment } from 'react';
import { endPoints } from 'shared/config/endPoints';
import { tabRoutes } from './tabRoutes';
import { Outlet } from 'react-router-dom';
import { TabBar } from 'widgets/TabBar';

export const serviceRoutes = [
  {
    path: endPoints.DEFAULT_PAGE,
    element: (
      <Fragment>
        <Outlet />
        <TabBar />
      </Fragment>
    ),
    children: tabRoutes,
  },
];
