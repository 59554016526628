import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { ELocales } from 'shared/translations/config';
import { ru, uz, enUS } from 'date-fns/locale';

const dateLocales = {
  [ELocales.en]: enUS,
  [ELocales.ru]: ru,
  [ELocales.uz]: uz,
};

export const useGetCookies = () => {
  useEffect(() => {
    const session = Cookies.get('click-web-session');
    const theme = Cookies.get('click-theme');
    const appVersion = Cookies.get('click-appversion');
    const platform = Cookies.get('click-platform');
    const style = Cookies.get('click-style');

    // localStorage.setItem(
    //   LOCAL_STORAGE_CREDENTIALS,
    //   JSON.stringify({ lang, session, theme, appVersion, platform, style }),
    // );

    // if (theme) {
    //   if (theme === 'dark') {
    //     themeModel.events.setTheme(EThemeTypes.light);
    //   }
    //   if (theme === 'light') {
    //     themeModel.events.setTheme(EThemeTypes.dark);
    //   }
    // }
  }, []);
};
