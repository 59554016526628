import { IOption } from 'shared/ui/molecules/Options/types';

export const regions: IOption[] = [
  {
    id: 3,
    label: 'regions.andijan',
  },
  {
    id: 6,
    label: 'regions.bukhara',
  },
  {
    id: 8,
    label: 'regions.jizzax',
  },
  {
    id: 10,
    label: 'regions.qashqadaryo',
  },
  {
    id: 12,
    label: 'regions.navoi',
  },
  {
    id: 14,
    label: 'regions.namangan',
  },
  {
    id: 18,
    label: 'regions.samarkand',
  },
  {
    id: 22,
    label: 'regions.surxondaryo',
  },
  {
    id: 24,
    label: 'regions.sirdaryo',
  },
  {
    id: 26,
    label: 'regions.tashkent',
  },
  {
    id: 27,
    label: 'regions.tashkentRegion',
  },
  {
    id: 30,
    label: 'regions.fergana',
  },
  {
    id: 33,
    label: 'regions.xorezm',
  },
  {
    id: 35,
    label: 'regions.karakalpakstan',
  },
];
