import { createEffect, createEvent, sample } from 'effector';
import { IUpdateAddress, TUpdateServiceAddressFx } from '../types';
import { servicesApi } from '../api';
import { selectedService } from './selectedService';
import { services } from './services';
import { EToastType, IShowToast } from 'shared/types/toast';
import { showToastEvent } from 'shared/model/toast';

const updateAddress = createEvent<IUpdateAddress>();
const updateAddressFx = createEffect<TUpdateServiceAddressFx>(
  servicesApi.updateServiceAddress,
);

const $isUpdatePending = updateAddressFx.pending;

sample({
  clock: updateAddress,
  source: selectedService.stores.$service,
  fn: (service, newAddress) => ({ shopId: service.id, params: newAddress }),
  target: updateAddressFx,
});

sample({
  clock: updateAddressFx.doneData,
  fn: (res) => res.data,
  target: [selectedService.events.updateService, services.events.updateService],
});

sample({
  clock: updateAddressFx.doneData,
  fn: (): IShowToast => ({
    toastParams: {
      type: EToastType.Success,
      messageKey: 'toast.addressUpdated',
    },
  }),
  target: showToastEvent,
});

export const address = {
  events: {
    updateAddress,
  },
  pendings: {
    $isUpdatePending,
  },
};
