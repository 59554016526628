import React, { FC } from 'react';
import { Box } from '../Box';
import { backgroundImageCss } from './style';
import { FileUpload } from 'shared/ui/molecules/FileUpload';
import { servicesModel } from 'entities/Services/model';
import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

export const BackgroundImage: FC = () => {
  const { t } = useTranslation();
  const [isPending, service] = useUnit([
    servicesModel.background.pendings.$isPending,
    servicesModel.selectedService.stores.$service,
  ]);

  const onChange = (files: FileList) => {
    const formData = new FormData();
    formData.append('background', files[0]);
    servicesModel.background.events.updateBackground(formData);
  };

  return (
    <Box
      id="backgroundImg"
      title={t('shop.bg.title')}
      contentClassName={backgroundImageCss.container}>
      <FileUpload
        onChange={onChange}
        className={backgroundImageCss.fileUploader}
        isPending={isPending}
        src={service?.background}
        withButton={Boolean(service?.background)}
      />
    </Box>
  );
};
