import { format } from 'date-fns';

export const generateTimeArray = () => {
  // Генерация массива часов от 00 до 23
  const hours = Array.from({ length: 24 }, (_, index) =>
    format(new Date(2021, 0, 1, index), 'HH'),
  );

  // Генерация массива минут от 00 до 59
  const minutes = Array.from({ length: 60 }, (_, index) =>
    format(new Date(2021, 0, 1, 0, index), 'mm'),
  );

  return { hours, minutes };
};
