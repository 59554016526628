import React from 'react';
import { optionsCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { NoData } from 'shared/ui/molecules/NoData';
import { IOption, IOptionsProps } from './types';
import { CheckBox } from 'shared/ui/atoms/CheckBox';
import { useTranslation } from 'react-i18next';

export const Options = <T,>({
  options,
  value,
  onChange,
  onClose,
}: IOptionsProps<T>) => {
  const { t } = useTranslation();

  const onSelect = (data: IOption<T>) => {
    onChange?.(data);
    onClose?.();
  };

  return (
    <div className={optionsCss.container}>
      {!options?.length && <NoData />}
      <div className={optionsCss.list}>
        {options?.map((option) => (
          <button
            key={option.id}
            className={optionsCss.button}
            type="button"
            onClick={() => onSelect(option)}>
            <Text size="h3">{t(option.label)}</Text>
            <CheckBox isChecked={option.id === value?.id} isDisabled />
          </button>
        ))}
      </div>
    </div>
  );
};
