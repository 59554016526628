import React, { FC, useEffect } from 'react';
import { Box } from '../Box';
import { Field } from '../Field';
import { TOnChange, TOnSubmit } from 'shared/types/eventHandlers';
import { baseInfoCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { sharedCss } from 'shared/css';
import { useForm } from 'effector-forms';
import { shopModel } from 'pages/Shop/model';
import { useUnit } from 'effector-react';
import { servicesModel } from 'entities/Services/model';
import { Button } from 'shared/ui/atoms/Button';
import { shopCss } from 'pages/Shop/style';
import { NumberFormatValues } from 'react-number-format';
import { useTranslation } from 'react-i18next';

export const BaseInfo: FC = () => {
  const { t } = useTranslation();
  const [selectedService, isAllServicesPending, isPending] = useUnit([
    servicesModel.selectedService.stores.$service,
    servicesModel.services.pendings.isAllServicesPending,
    shopModel.baseInfoForm.pendings.$isPending,
  ]);
  const { fields, errorText, submit } = useForm(
    shopModel.baseInfoForm.stores.$form,
  );

  useEffect(() => {
    selectedService && shopModel.baseInfoForm.events.initForm();
  }, [selectedService]);

  const onChange: TOnChange = (e) => {
    fields[e.target.name].onChange(e.target.value);
  };

  const onValueChange =
    (name: string) =>
    ({ value }: NumberFormatValues) => {
      fields[name].onChange(value);
    };

  const onSubmit: TOnSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <Box
      id="baseInfo"
      title={t('shop.baseInfo.title')}
      contentClassName={baseInfoCss.container}>
      <form onSubmit={onSubmit}>
        <Field
          label={t('shop.baseInfo.name')}
          value={fields.name.value}
          errorKey={errorText('name')}
          inputProps={{
            name: 'name',
            value: fields.name.value,
            onChange,
          }}
        />
        <Field
          label={t('shop.baseInfo.contact')}
          isEditable={false}
          value={selectedService?.admin_phone}
          inputProps={{
            readOnly: true,
          }}
          patternProps={{
            format: '+### (##) ### ## ##',
            mask: '_',
            allowEmptyFormatting: true,
          }}
        />
        <Field
          label={t('shop.baseInfo.supportContact')}
          value={fields.support_phone.value}
          errorKey={errorText('support_phone')}
          inputProps={{
            name: 'support_phone',
            placeholder: t('shop.baseInfo.supportContactPlaceholder'),
            value: fields.support_phone.value,
          }}
          patternProps={{
            format: '+### (##) ### ## ##',
            mask: '_',
            allowEmptyFormatting: true,
            onValueChange: onValueChange('support_phone'),
          }}
        />
        <div className={baseInfoCss.boxOffice}>
          <div className={baseInfoCss.boxOfficeHeaderContent}>
            <Text color="secondary">{t('shop.baseInfo.service')}</Text>
            <div
              className={`${sharedCss.rowCenter} ${baseInfoCss.boxOfficeStatus}`}
              data-is-active={true}>
              <div className={`${baseInfoCss.boxOfficeStatusDot}`} />
              <span>{t('shop.baseInfo.connected')}</span>
            </div>
          </div>
          <Text
            className={baseInfoCss.selectedBoxOffice}
            isEmpty={isAllServicesPending}>
            {selectedService?.name ?? t('shop.baseInfo.unknown')}
          </Text>
        </div>
        <Button
          styleType="accent"
          type="submit"
          className={shopCss.saveBtn}
          isLoading={isPending}>
          {t('save')}
        </Button>
      </form>
    </Box>
  );
};
