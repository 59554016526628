import React, { FC, lazy } from 'react';
import { useUnit } from 'effector-react';
import { authModel } from 'entities/Auth/model';
import { RouterRoutesElement } from 'processes';
import { serviceRoutes } from 'processes/Router/config/serviceRoutes';
import { renderRoutes } from 'processes/Router/lib/renderRoutes';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import { endPoints } from 'shared/config/endPoints';
import { onboardRoutes } from 'processes/Router/config/onboardRoutes';
import { servicesModel } from 'entities/Services/model';

const Auth = lazy(() =>
  import('pages/Auth').then(({ Auth }) => ({
    default: Auth,
  })),
);

export const AppRouter: FC = () => {
  const [merchantInfo, services] = useUnit([
    authModel.creds.stores.$merchantInfo,
    servicesModel.services.stores.$list,
  ]);

  if (merchantInfo) {
    const defaultPath = services.length
      ? endPoints.MY_SHOPS
      : endPoints.ONBOARD;

    return (
      <BrowserRouter>
        <Routes>
          <Route
            path={endPoints.DEFAULT_PAGE}
            element={<RouterRoutesElement />}>
            {renderRoutes([...onboardRoutes, ...serviceRoutes])}
            <Route path="*" index element={<Navigate to={defaultPath} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path={endPoints.DEFAULT_PAGE} element={<Outlet />}>
          <Route path={endPoints.LOGIN} element={<Auth />} />
          <Route path="*" element={<Navigate to={endPoints.DEFAULT_PAGE} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
