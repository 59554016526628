import { createEvent, createStore, sample } from 'effector';
import { IService } from 'shared/types/api/services';
import { endPoints } from 'shared/config/endPoints';
import { redirect } from 'shared/lib/redirect';
import { LOCAL_STORAGE_SELECTED_SERVICE } from 'shared/config/constants';

const devInitService =
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_SELECTED_SERVICE)) ?? null;

const initService =
  process.env.NODE_ENV === 'development' ? devInitService : null;

const resetService = createEvent();
const setService = createEvent<IService>();
const updateService = createEvent<IService>();
const $service = createStore<IService>(null);

sample({
  clock: [setService, updateService],
  fn: (value) => {
    if (value) {
      localStorage.setItem(
        LOCAL_STORAGE_SELECTED_SERVICE,
        JSON.stringify(value),
      );
      return value;
    }
    localStorage.removeItem(LOCAL_STORAGE_SELECTED_SERVICE);
    return null;
  },
  target: $service,
});

sample({
  clock: setService,
  filter: Boolean,
  fn: () => endPoints.DEFAULT_PAGE,
  target: redirect,
});

sample({
  clock: resetService,
  fn: () => null,
  target: setService,
});

export const selectedService = {
  stores: {
    $service,
  },
  events: {
    resetService,
    updateService,
    setService,
  },
};
