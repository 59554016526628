import { css } from '@linaria/core';

const container = css`
  gap: 24px;
  display: flex;
  flex-direction: column;
  padding: 0 16px 24px 16px;
`;

const header = css`
  padding-inline: 16px;
`;

const column = css`
  &[data-is-odd='true'] {
    div {
      justify-content: flex-end !important;
      padding-right: 15px !important;

      p {
        min-width: 50px;
        text-align: center;
      }
    }
  }

  &[data-is-odd='false'] {
    div {
      justify-content: flex-start !important;
      padding-left: 15px !important;

      p {
        min-width: 50px;
        text-align: center;
      }
    }
  }
`;

export const timePickerCss = {
  container,
  header,
  column,
};
