import React, { FC, useState } from 'react';
import Picker from 'react-mobile-picker';
import { timePickerCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { generateTimeArray } from './lib/generateTimeArray';
import { parseTime } from './lib/parseTime';
import { ITimePickerProps } from './types';
import { Button } from 'shared/ui/atoms/Button';
import { useTranslation } from 'react-i18next';

export const TimePicker: FC<ITimePickerProps> = ({ value, onDone }) => {
  const { t } = useTranslation();
  const [result, setResult] = useState(parseTime(value));
  const time = generateTimeArray();

  const onSubmit = () => {
    onDone(`${result.hours}:${result.minutes}`);
  };

  return (
    <div className={timePickerCss.container}>
      <Picker<typeof result>
        wheelMode="natural"
        value={result}
        onChange={(value) => {
          console.log(value);
          setResult({ hours: value.hours, minutes: value.minutes });
        }}>
        {Object.entries(time).map(([key, values], index) => {
          return (
            <Picker.Column
              key={`${name}_${key}`}
              name={key}
              className={timePickerCss.column}
              data-is-odd={index % 2 === 0}>
              {values.map((value) => {
                const isActive = value === result[key];
                return (
                  <Picker.Item key={`${name}_${key}_${value}`} value={value}>
                    <Text
                      size={isActive ? 'h2' : 'h3'}
                      weight="medium"
                      color={isActive ? 'primary' : 'secondary'}>
                      {value}
                    </Text>
                  </Picker.Item>
                );
              })}
            </Picker.Column>
          );
        })}
      </Picker>
      <Button size="lg" styleType="accent" onClick={onSubmit}>
        {t('save')}
      </Button>
    </div>
  );
};
