import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const list = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  padding: 0 16px 16px 16px;
`;

const button = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 16px
  min-height: 56px;

  ${theming((theme) => ({ backgroundColor: theme.background.bg2 }))}

  &:active {
    opacity: 0.6
  }
`;

export const optionsCss = {
  container,
  list,
  button,
};
