import { address } from './address';
import { background } from './background';
import { logo } from './logo';
import { selectedService } from './selectedService';
import { services } from './services';
import { shopPublishedModal } from './shopPublishedModal';
import { status } from './status';

export const servicesModel = {
  services,
  selectedService,
  address,
  status,
  background,
  logo,
  shopPublishedModal,
};
