import { Store } from 'effector';
import { Rule } from 'effector-forms';
import { FULLNAME, ONLY_NUMBERS } from 'shared/config/regexp';

export const rules = {
  required: function <T = any, F = any, S = any>(
    text?: string,
    params?: {
      $store?: Store<S>;
      isOptional?: (value: T, form: F, source: S) => boolean;
    },
  ): Rule<T, F, S> {
    return {
      name: 'required',
      ...(params?.$store && { source: params.$store }),
      validator: (value, form, source) => {
        if (params?.isOptional(value, form, source)) {
          return {
            isValid: true,
          };
        }
        return {
          isValid: Boolean(value),
          errorText: text || 'errors.required',
        };
      },
    };
  },
  onlyNumbers: () => ({
    name: 'onlyNumbers',
    validator: (value: string) => {
      return {
        isValid: new RegExp(ONLY_NUMBERS).test(value) || value === '',
        errorText: 'errors.onlyNumbers',
      };
    },
  }),
  minLength: (length: number) => ({
    name: 'minLength',
    validator: (value: string) => {
      return {
        isValid: value.length >= length,
        errorText: 'errors.minLength',
      };
    },
  }),
  pattern: (regexp: RegExp, text?: string) => ({
    name: 'pattern',
    validator: (value: string) => ({
      isValid: new RegExp(regexp).test(value),
      errorText: text ?? 'errors.invalidPattern',
    }),
  }),
  fullName: (): Rule<string> => ({
    name: 'fullName',
    validator: (value) => ({
      isValid: new RegExp(FULLNAME).test(value),
      errorText: 'errors.fullname',
    }),
  }),
};
