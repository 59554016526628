import React, { FC } from 'react';
import { Box } from '../Box';
import { locationCss } from './style';
import { TOnClick } from 'shared/types/eventHandlers';
import { defaultMapCenter } from 'shared/config/constants';
import { Image } from 'shared/ui/atoms/Image';
import { sharedCss } from 'shared/css';
import { Svg } from 'shared/ui/atoms/Svg';
import { Text } from 'shared/ui/atoms/Text';
import { Button } from 'shared/ui/atoms/Button';
import { fromLatLng } from 'react-geocode';
import { mapModalModel } from 'features/MapModal/model';
import { servicesModel } from 'entities/Services/model';
import { useUnit } from 'effector-react';
import { Input } from 'shared/ui/atoms/Input';
import { RegionSelector } from 'features/RegionSelector';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { IOption } from 'shared/ui/molecules/Options/types';
import { regions } from 'features/RegionSelector/config';
import { useTranslation } from 'react-i18next';

export const Location: FC = () => {
  const { t } = useTranslation();
  const modal = useModal('optionsModal');
  const [isPending, service] = useUnit([
    servicesModel.address.pendings.$isUpdatePending,
    servicesModel.selectedService.stores.$service,
  ]);
  const hasCoords = service?.latitude && service?.longitude;
  const lat = Number(service?.latitude) || defaultMapCenter.lat;
  const lng = Number(service?.longitude) || defaultMapCenter.lng;
  const region = regions.find((region) => region.id === service.region_id);

  const onClick: TOnClick<any> = (e) => {
    mapModalModel.modal.setVisible({
      isOpen: true,
      coords: {
        lat,
        lng,
      },
      onClick: async (coords) => {
        servicesModel.address.events.updateAddress({
          region_id: service.region_id,
          latitude: coords.lat.toString(),
          longitude: coords.lng.toString(),
          address: (await fromLatLng(
            Number(coords.lat),
            Number(coords.lng),
          ).then(({ results }) => {
            return results[0].formatted_address;
          })) as string,
        });
      },
    });
  };

  const onChangeRegion = (value: IOption) => {
    servicesModel.address.events.updateAddress({
      region_id: value.id as number,
      latitude: service.latitude,
      longitude: service.longitude,
      address: service.address,
    });
  };

  const onClickRegion = () => {
    NiceModal.show('optionsModal', {
      title: t('shop.location.selectRegion'),
      content: (
        <RegionSelector
          value={service?.region_id}
          onChange={onChangeRegion}
          onClose={() => modal.hide()}
        />
      ),
    });
  };

  return (
    <Box
      id="location"
      title={t('shop.location.title')}
      contentClassName={locationCss.container}>
      <Input
        classWrapper={locationCss.region}
        labelKey={t('shop.location.regionLabel')}
        inputProps={{
          placeholder: t('shop.location.regionPlaceholder'),
          readOnly: true,
          value: t(region?.label),
          onClick: onClickRegion,
        }}
      />
      <div className={locationCss.map}>
        <Image
          src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=16&size=600x300&maptype=roadmap&markers=color:red%7C${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
        />
      </div>
      {service?.address && (
        <div
          className={`${sharedCss.rowVerticalCenter} ${locationCss.addressBox}`}>
          <Svg idIcon="icMarker" width={16} height={16} />
          <Text
            size="subtext"
            color="secondary"
            className={locationCss.address}>
            {service.address}
          </Text>
        </div>
      )}
      <Button
        styleType="accent"
        className={locationCss.locationBtn}
        onClick={onClick}>
        {t(`shop.location.${hasCoords ? 'edit' : 'select'}`)}
      </Button>
    </Box>
  );
};
