import React from 'react';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import { OutputFormat, setDefaults } from 'react-geocode';
import { globalClass, toastContainerClass } from 'shared/theme/GlobalStyles';
import { useGetCookies } from './lib/useGetCookies';
import { inspect, type Message } from 'effector/inspect';
import NiceModal from '@ebay/nice-modal-react';
import { useSetTheme } from './lib/useSetTheme';
import { AppRouter } from './router';
import 'react-toastify/dist/ReactToastify.css';

Modal.setAppElement('#root');

setDefaults({
  key: process.env.REACT_APP_GEOCODING_API_KEY,
  language: 'ru',
  outputFormat: OutputFormat.JSON,
});

function logInspectMessage(m: Message) {
  const { name, value } = m;
  if (name === 'failData' && value) {
    console.error('FAIL_DATA', value);
  }
}

inspect({
  fn: (m) => {
    logInspectMessage(m);
  },
});

const App = () => {
  useSetTheme();
  useGetCookies();
  return (
    <NiceModal.Provider>
      <div className={globalClass}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          className={toastContainerClass}
          closeButton={false}
        />
        <AppRouter />
      </div>
    </NiceModal.Provider>
  );
};

export default App;
