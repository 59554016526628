export const closeApp = () => {
  window.location.href = 'https://bwebview.click.uz/';
  // process.env.NODE_ENV === 'development'
  //   ? 'https://business-test.click.uz/'
  //   : 'https://bwebview.click.uz/';

  // const anchorEl = document.createElement('a');
  // anchorEl.href = document.referrer;
  // anchorEl.click();
  // anchorEl.remove();
};
