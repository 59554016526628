import React, { FC, useEffect, useRef } from 'react';
import { PageContainer } from 'shared/ui/molecules/PageContainer';
import { Header } from 'widgets/Header';
import { shopCss } from './style';
import { BaseInfo } from './ui/BaseInfo';
import { WorkingHours } from './ui/WorkingHours';
import { BackgroundImage } from './ui/BackgroundImage';
import { Location } from './ui/Location';
import { TalegramChannel } from './ui/TalegramChannel';
import { useUnit } from 'effector-react';
import { scrollToElIdModel } from 'shared/model/scrollToElId';
import { Logo } from './ui/Logo';
import { WelcomeText } from './ui/WelcomeText';
import { TimeManager } from './ui/TimeManager';
import { Status } from './ui/Status';
import { useTranslation } from 'react-i18next';
import { useScrollToElementId } from './lib/useScrollToElementId';

export const Shop: FC = () => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>();
  const [scrollToElId] = useUnit([scrollToElIdModel.stores.$elId]);
  useScrollToElementId(scrollToElId);

  return (
    <PageContainer
      ref={containerRef}
      id="shopSettins"
      isScrollable
      className={shopCss.container}>
      <Header scrollEl="shopSettins" hasClose={false} title={t('shop.title')} />
      <div className={shopCss.content}>
        <Logo />
        <Status />
        <BaseInfo />
        <WorkingHours />
        <TimeManager />
        <BackgroundImage />
        <WelcomeText />
        <Location />
        <TalegramChannel />
      </div>
    </PageContainer>
  );
};
