import React, { FC } from 'react';
import { importCss } from './style';
import { Text } from 'shared/ui/atoms/Text';
import { sharedCss } from 'shared/css';
import { Button } from 'shared/ui/atoms/Button';
import { TOnClick } from 'shared/types/eventHandlers';
import { useUnit } from 'effector-react';
import { menuModel } from 'pages/Menu/model';
import {
  GET_CSV_TEMPLATE,
  GET_CSV_TEMPLATE_WITH_CODE,
} from 'shared/api/endpoints';
import { servicesModel } from 'entities/Services/model';
import { useTranslation } from 'react-i18next';

export const Import: FC = () => {
  const { t } = useTranslation();
  const [file, isPending, service] = useUnit([
    menuModel.file.stores.$file,
    menuModel.file.pendings.$isImportPending,
    servicesModel.selectedService.stores.$service,
  ]);
  const templateEndpoint =
    service.fiskal_type === '1' ? GET_CSV_TEMPLATE : GET_CSV_TEMPLATE_WITH_CODE;

  const onUploadPattern: TOnClick<HTMLButtonElement> = (e) => {
    const inputEl = document.createElement('input');
    inputEl.type = 'file';
    inputEl.hidden = true;
    inputEl.accept = '.csv,.xls,.xlsx';

    inputEl.click();

    inputEl.onchange = () => {
      menuModel.file.events.setFile(inputEl.files[0]);
      inputEl.remove();
    };

    inputEl.oncancel = () => {
      inputEl.remove();
    };
  };

  const onImport = () => {
    menuModel.file.events.importDoc();
  };

  return (
    <div className={importCss.container}>
      <Text size="h3">{t('menu.import.title')}</Text>
      <div className={importCss.content}>
        <div className={`${importCss.header} ${sharedCss.rowBetween}`}>
          <div className={importCss.headerLeft}>
            <Text size="h3">{t('menu.import.label')}</Text>
            <a
              download
              href={process.env.REACT_APP_API_HOST + templateEndpoint}
              className={importCss.downloadPattern}>
              {t('menu.import.download')}
            </a>
          </div>
          <span className={importCss.status} data-is-uploaded={Boolean(file)}>
            {t(`menu.import.${file ? 'uploaded' : 'notUploaded'}`)}
          </span>
        </div>
        {file && <Text size="h3">{file.name}</Text>}
        {file && (
          <Button
            onClick={onImport}
            isLoading={isPending}
            className={importCss.importButton}>
            {t('menu.import.importData')}
          </Button>
        )}
        <Button styleType="secondary" fontSize="14" onClick={onUploadPattern}>
          {t('menu.import.upload')}
        </Button>
      </div>
    </div>
  );
};
