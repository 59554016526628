import { css } from '@linaria/core';
import { theming } from 'shared/theme';

const container = css`
  padding: 2.5px 12px;
  border-radius: 50px;
  font-size: 11px;

  ${theming((theme) => ({
    color: theme.text.secondary,
  }))}

  &[data-variant='default'] {
    ${theming((theme) => ({
      backgroundColor: theme.background.bg1,
    }))}
  }

  &[data-variant='secondary'] {
    ${theming((theme) => ({
      backgroundColor: theme.background.bg2,
    }))}
  }

  &[data-variant='primary'] {
    ${theming((theme) => ({
      backgroundColor: theme.background.bg4,
      color: theme.colors.white
    }))}
  }

  &[data-variant='accent'] {
    ${theming((theme) => ({
      backgroundColor: theme.colors.blue,
    }))}
  }

  &[data-status='created'],
  &[data-status='in progress'],
  &[data-status='cart'],
  &[data-status='paid'] {
    ${theming((theme) => ({
      backgroundColor: theme.colors.orange,
      color: theme.colors.white,
    }))}
  }

  &[data-status='done'] {
    ${theming((theme) => ({
      backgroundColor: theme.colors.green,
      color: theme.colors.white,
    }))}
  }

  &[data-status='ready'] {
    ${theming((theme) => ({
      backgroundColor: theme.colors.blue,
      color: theme.colors.white,
    }))}
  }

  &[data-status='canceled'] {
    ${theming((theme) => ({
      backgroundColor: theme.colors.red,
      color: theme.colors.white,
    }))}
  }
`;

export const orderTagCss = {
  container,
};
