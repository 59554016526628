import { endPoints } from 'shared/config/endPoints';

export const checklistConfig = [
  {
    elId: 'baseInfo',
    name: 'home.checklist.pointOne',
    actionName: 'home.checklist.pointOneAction',
    redirectTo: endPoints.SHOP,
  },
  {
    elId: 'workingHours',
    name: 'home.checklist.pointTwo',
    actionName: 'home.checklist.pointTwoAction',
    redirectTo: endPoints.SHOP,
  },
  {
    elId: 'backgroundImg',
    name: 'home.checklist.pointThree',
    actionName: 'home.checklist.pointThreeAction',
    redirectTo: endPoints.SHOP,
  },
  {
    elId: 'location',
    name: 'home.checklist.pointFour',
    actionName: 'home.checklist.pointFourAction',
    redirectTo: endPoints.SHOP,
  },
  {
    elId: 'telegramChannel',
    name: 'home.checklist.pointFive',
    actionName: 'home.checklist.pointFiveAction',
    redirectTo: endPoints.SHOP,
  },
  {
    elId: 'menu',
    name: 'home.checklist.pointSix',
    actionName: 'home.checklist.pointSixAction',
    redirectTo: endPoints.MENU,
  },
];
