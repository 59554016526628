import { createEffect, createEvent, createStore, sample } from 'effector';
import { servicesApi } from '../api';
import {
  IServicesChecklist,
  TGetBusinessServicesFx,
  TGetServicesFx,
} from '../types';
import { IService } from 'shared/types/api/services';
import { IBusinessService } from 'shared/types/api/businessServices';
import { pending } from 'patronum';
import { getServiceChecklist } from 'shared/lib/getServiceChecklist';

const resetAll = createEvent();

const $list = createStore<IService[]>(null).reset(resetAll);
const $servicesCount = createStore<number>(0).reset(resetAll);

const getServices = createEvent();
const getServicesFx = createEffect<TGetServicesFx>(servicesApi.getServices);

const $isPending = getServicesFx.pending;

const $servicesChecklist = createStore<IServicesChecklist>(null);

sample({
  clock: getServices,
  target: getServicesFx,
});

sample({
  clock: getServicesFx.doneData,
  fn: (res) => res.data,
  target: $list,
});

sample({
  clock: $list,
  filter: (services) => Boolean(services?.length),
  fn: (services) => {
    const data: IServicesChecklist = {};

    for (const service of services) {
      const checklist = getServiceChecklist(service);
      const filledCount = Object.values(checklist).filter(
        (isChecked) => isChecked,
      ).length;
      const allFilled = filledCount === 6;

      data[service.id] = {
        checklist,
        allFilled,
        filledCount,
        totalCount: 6,
      };
    }

    return data;
  },
  target: $servicesChecklist,
});

sample({
  clock: $list,
  fn: (list) => list?.length ?? 0,
  target: $servicesCount,
});

const pushService = createEvent<IService>();

sample({
  clock: pushService,
  source: $list,
  filter: (_, newService) => Boolean(newService),
  fn: (list, newService) => [...list, newService],
  target: $list,
});

const updateService = createEvent<IService>();

sample({
  clock: updateService,
  source: $list,
  filter: (list, newService) => Boolean(list?.length && newService),
  fn: (list, newService) =>
    list.map((service) =>
      service.id === newService.id ? newService : service,
    ),
  target: $list,
});

sample({
  clock: updateService,
  source: $list,
  filter: (list) => !list,
  target: getServices,
});

const $businessServices = createStore<IBusinessService[]>(null);
const getBusinessServices = createEvent();
const getBusinessServicesFx = createEffect<TGetBusinessServicesFx>(
  servicesApi.fetchBusinessServices,
);

sample({
  clock: getBusinessServices,
  target: getBusinessServicesFx,
});

sample({
  clock: getBusinessServicesFx.doneData,
  source: $businessServices,
  filter: (list) => !list,
  fn: (_, res) => res.data,
  target: $businessServices,
});

const isAllServicesPending = pending({
  effects: [getBusinessServicesFx, getServicesFx],
});

export const services = {
  stores: {
    $businessServices,
    $list,
    $servicesCount,
    $servicesChecklist,
  },
  events: {
    getBusinessServices,
    getServices,
    pushService,
    updateService,
    resetAll,
  },
  pendings: {
    $isPending,
    isAllServicesPending,
  },
};
