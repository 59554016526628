import React, { FC, useEffect } from 'react';
import { Box } from '../Box';
import { telegramChannelCss } from './style';
import { Button } from 'shared/ui/atoms/Button';
import { shopModel } from 'pages/Shop/model';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-forms';
import { TOnChange, TOnSubmit } from 'shared/types/eventHandlers';
import { shopCss } from 'pages/Shop/style';
import { Textarea } from 'shared/ui/atoms/Textarea';
import { useTranslation } from 'react-i18next';

export const WelcomeText: FC = () => {
  const { t } = useTranslation();
  const [isPending] = useUnit([shopModel.previewTextForm.pendings.$isPending]);
  const { fields, errorText, submit } = useForm(
    shopModel.previewTextForm.stores.$form,
  );

  useEffect(() => {
    shopModel.previewTextForm.events.initForm();
  }, []);

  const onChange: TOnChange = (e) => {
    fields[e.target.name].onChange(e.target.value);
  };

  const onClickIntruction = () => {
    shopModel.telegramForm.tgModalFactory.setVisible(true);
  };

  const onSubmit: TOnSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <Box
      id="welcomeText"
      title={t('shop.welcomeText.title')}
      subtitle={t('shop.welcomeText.subtitle')}
      contentClassName={telegramChannelCss.container}
      onClickHeaderAction={onClickIntruction}>
      <form onSubmit={onSubmit}>
        <Textarea
          labelKey={t('shop.welcomeText.label')}
          textareaProps={{
            placeholder: t('shop.welcomeText.placeholder'),
            name: 'preview_text',
            value: fields.preview_text.value,
            onChange,
          }}
          errorKey={errorText('preview_text')}
          maxLength={60}
        />
        <Button
          styleType="accent"
          type="submit"
          className={shopCss.saveBtn}
          isLoading={isPending}>
          {t('save')}
        </Button>
      </form>
    </Box>
  );
};
