import { createEffect, sample } from 'effector';
import { createForm } from 'effector-forms';
import { rules } from 'shared/lib/rules';
import { modal } from './modal';
import { infoModalModel } from 'features/InfoModal/model';
import { ISendApplicationFormFields, TSendApplicationFx } from '../types';
import { sendApplication } from '../api';
import { IInfoModalParams } from 'features/InfoModal/types';
import { servicesModel } from 'entities/Services/model';
import { closeApp } from 'shared/lib/closeApp';

const $applicationForm = createForm<ISendApplicationFormFields>({
  fields: {
    service_name: {
      init: '',
      filter: (value) => value.length <= 256,
      rules: [rules.required()],
    },
    name: {
      init: '',
      filter: (value) => value.length <= 256,
      rules: [rules.required(), rules.fullName()],
    },
    phone: {
      init: '998',
      rules: [
        rules.required(),
        rules.pattern(/^998+([0-9]{2})+([0-9]{7})$/, 'errors.invalidPhone'),
      ],
    },
  },
  validateOn: ['change', 'submit'],
});

const sendApplicationFx = createEffect<TSendApplicationFx>(sendApplication);

const $isPending = sendApplicationFx.pending;

sample({
  clock: $applicationForm.formValidated,
  fn: (fields) => ({
    ...fields,
    is_developer: false,
    became_developer: false,
    ads: false,
    community: false,
    vitrina: false,
    mini_app: false,
    suggest_service: false,
  }),
  target: sendApplicationFx,
});

sample({
  clock: sendApplicationFx.doneData,
  target: modal.resetModal,
});

sample({
  clock: sendApplicationFx.doneData,
  source: servicesModel.services.stores.$servicesCount,
  fn: (servicesCount): IInfoModalParams => ({
    isOpen: true,
    title: 'toast.applicationSent',
    onClose: () => {
      servicesCount ? infoModalModel.modal.resetModal() : closeApp();
    },
  }),
  target: infoModalModel.modal.setVisible,
});

export const form = {
  stores: {
    $applicationForm,
  },
  pendings: {
    $isPending,
  },
};
